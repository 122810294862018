<template>
  <b-card no-body>
    <b-table
      responsive="sm"
      :fields="fields"
      :items="dataList"
      striped
      hover
    >
      <template #cell(adi)="data">
        {{ data.item.adi }} {{ data.item.soyadi }}
      </template>
      <template #cell(created)="data">
        {{ moment(data.item.created).format('llll') }}
      </template>
      <template #cell(id)="data">
        <b-dropdown
          variant="primary"
          text="Görüntüle"
          left
          split
          size="sm"
          @click="$router.push('/hr/career-form/view/' + data.item.id)"
        >
          <b-dropdown-item
            :href="downloadUrl + data.item.id"
            target="_blank"
          >
            <feather-icon icon="PrinterIcon" />
            <span class="align-middle ml-50">Yazdır</span>
          </b-dropdown-item>
          <b-dropdown-divider />
          <b-dropdown-item @click="removeData(data.item.id)">
            <feather-icon icon="XIcon" />
            <span class="align-middle ml-50">Sil</span>
          </b-dropdown-item>
        </b-dropdown>
      </template>
    </b-table>
    <b-card-footer>
      <b-pagination
        v-model="currentPage"
        :total-rows="dataCount"
        :per-page="perPage"
        first-number
        last-number
        prev-class="prev-item"
        next-class="next-item"
      >
        <template #prev-text>
          <feather-icon
            icon="ChevronLeftIcon"
            size="18"
          />
        </template>
        <template #next-text>
          <feather-icon
            icon="ChevronRightIcon"
            size="18"
          />
        </template>
      </b-pagination>
    </b-card-footer>
  </b-card>
</template>

<script>
import {
  BCard, BTable, BCardFooter, BPagination, BDropdown, BDropdownItem, BDropdownDivider,
} from 'bootstrap-vue'

export default {
  name: 'Index',
  components: {
    BCard,
    BTable,
    BCardFooter,
    BPagination,
    BDropdown,
    BDropdownItem,
    BDropdownDivider,
  },
  data() {
    return {
      currentPage: 1,
      perPage: 10,
      fields: [
        {
          key: 'adi',
          label: 'Adı Soyadı',
          thClass: 'align-middle',
        },
        {
          key: 'telefon',
          label: 'Telefon',
          thClass: 'align-middle',
        },
        {
          key: 'created',
          label: 'Tarhi',
          thClass: 'align-middle',
        },
        {
          key: 'id',
          label: '',
          thClass: 'align-middle width-100',
          tdClass: 'text-right',
        },
      ],
      dataQuery: {
        select: [
          'com_careerform.id AS id',
          'com_careerform.adi AS adi',
          'com_careerform.soyadi AS soyadi',
          'com_careerform.telefon AS telefon',
          'com_careerform.created AS created',
        ],
        limit: 25,
        start: 0,
      },
    }
  },
  computed: {
    downloadUrl() {
      return `${this.$store.state.app.baseURL}/exports/hr/careerform/pdf?token=${localStorage.getItem('downloadToken')}&id=`
    },
    dataList() {
      return this.$store.getters['hrCareerForm/dataList']
    },
    dataCount() {
      return this.$store.getters['hrCareerForm/dataCounts']
    },
  },
  watch: {
    currentPage(val) {
      const page = (val - 1) * 10
      this.pagination(page)
    },
  },
  created() {
    this.getDataList()
  },
  methods: {
    pagination(page) {
      this.dataQuery.start = page
      if (this.dataQuery.start === page) {
        this.getDataList()
      }
    },
    getDataList() {
      this.$store.dispatch('hrCareerForm/getDataList', this.dataQuery)
    },
    removeData(id) {
      this.$store.dispatch('hrCareerForm/deleteData', id)
    },
  },
}
</script>
